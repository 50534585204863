<!--
 * @Author: Leif
 * @Date: 2021-10-25 18:26:15
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-08-22 13:51:16
 * @FilePath: /zhy_dataview_fe/src/components/InsidePages/PeopleGroupList.vue
 * @Description: 通知消息-通讯录
-->
<template>
  <div class="PeopleGroupList">
    <div class="infinite-tip">
      已选:
      <span style="margin-left: 5px">{{ recipientUserIds.length }}</span>
      <span class="empty-btn" v-if="hasDelPeopleBtn" @click="clearPeopleEvent">清空</span>
    </div>
    <ul class="infinite-list" style="overflow: auto">
      <li v-for="(item, index) in recipientUserIds" :key="index" class="infinite-list-item">
        <div class="avatar">
          <img :src="require('@/assets/icons/icon_group.png')" alt="图标" />
        </div>
        <div class="people_cell">
          <div class="people_name">{{ item.userName }}</div>
        </div>
        <div class="operation" @click="removePeopleEvent(item)" v-if="hasDelPeopleBtn">
          <el-icon>
            <close />
          </el-icon>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { Close } from "@element-plus/icons";
export default {
  name: "PeopleGroupList",
  data() {
    return {
      index: 0,
      count: [],
    };
  },
  props: {
    // 是否包含删除单个用户按钮
    hasDelPeopleBtn: {
      type: Boolean,
      default: true,
    },
    recipientUserIds: {
      type: Array,
      default: [],
    },
  },
  components: {
    Close,
  },
  methods: {
    clearPeopleEvent() {
      this.$emit("clearPeople");
    },
    removePeopleEvent(item) {
      this.$emit("removePeople", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.infinite-tip {
  color: #fff;
  padding: 0 0 10px 8px;
  display: flex;

  span {
    color: #f05858;
    user-select: none;
    cursor: pointer;
  }

  .empty-btn {
    margin-left: auto;
  }
}

.infinite-list {
  height: 700px;
  padding: 0;
  margin-bottom: 10px;
  list-style: none;
  font-size: 16px;

  .infinite-list-item {
    user-select: none;
    display: flex;
    margin-top: 20px;
    color: #fff;

    .avatar {
      width: 52px;
      height: 52px;
      background: #30354d;
      margin-right: 10px;
      overflow: hidden;
      border-radius: 100%;

      img {
        width: 100%;
      }
    }

    .people_cell {
      width: 316px;
      display: flex;
      align-items: center;

      .people_name {
        font-size: 15px;
        color: rgba(255, 255, 255, 0.9);
      }
    }

    .operation {
      color: #f05858;
      cursor: pointer;
      position: relative;

      .el-icon {
        font-size: 24px;
        position: absolute;
        top: 50%;
        margin-top: -8px;
      }
    }
  }
}

.PeopleGroupList {
  overflow-y: auto;
  font-size: 16px;
}
</style>
