<template>
  <div :class="['rich-text', readOnly && 'rich-text__readonly']">
    <div id="wangeditor"></div>
    <div class="count">{{ strCount }} / 3000</div>
  </div>
</template>

<script>
import E from "wangeditor";
let editor = null;
export default {
  data() {
    return {
      strCount: 0,
    };
  },
  props: {
    // 是否是只读
    readOnly: {
      type: Boolean,
      default: false,
    },
    // 导入的文本
    importTxt: {
      type: String,
      default: "",
    },
  },
  watch: {
    importTxt(val) {
      editor.txt.html(val);
    },
  },
  mounted() {
    editor = new E("#wangeditor");
    editor.config.placeholder = this.readOnly ? "" : "请输入消息正文";
    editor.config.showFullScreen = false;
    editor.config.menus = ["fontSize", "foreColor", "backColor", "bold", "head", "italic", "underline"];
    editor.config.focus = false;
    editor.config.zIndex = 2;
    // 编辑器修改回调
    editor.config.onchange = (newHtml) => {
      const newText = editor.txt
        .text()
        .replace(/&nbsp;/gi, "")
        .trim();
      this.strCount = newText.length;
      this.$emit("editorChangeEvent", { newHtml, newText });
    };
    if (this.readOnly) {
      const timer = setTimeout(() => {
        editor.disable();
        clearTimeout(timer);
      });
    }
    editor.create();
  },
};
</script>

<style lang="scss">
.rich-text {
  position: relative;
  .count {
    color: #fff;
    position: absolute;
    bottom: 15px;
    right: 15px;
    z-index: 3;
    font-size: 12px;
  }
  .w-e-text {
    padding: 0 16px;
  }
  .w-e-toolbar {
    border: none !important;
  }
  .w-e-text-container {
    border: none !important;
    background-color: #292c3b;
    color: #fff;
    i {
      font-style: italic;
    }
    b {
      font-weight: bold;
    }
    .placeholder {
      font-size: 16px;
      margin-top: 3px;
      left: 18px;
      color: #c0c4cc;
    }
  }
  .w-e-toolbar p,
  .w-e-text-container p,
  .w-e-menu-panel p {
    font-size: 16px !important;
  }

  &.rich-text__readonly {
    .count,
    .w-e-toolbar {
      display: none;
    }
  }
}
</style>
